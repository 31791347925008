import React from "reactn";
import { MemberShip1, MemberShip2, LiveView2, LiveView3 } from "../../assets";
import GoogleMapReact from "google-map-react";
import axios from "axios";
import { OrangePin } from "../../assets";
import Checkout from "./checkout";
import "../../assets/css/componentSpecificCss/cssforProgram.css";
import Radio from "@material-ui/core/Radio";

import { withStyles } from "@material-ui/core/styles";
import SummarySubTotalMembership from "./SpecificPageUtils/SummarySubTotalMembership";

const styles = {
  plan: {
    transition: "all 300ms",
    boxShadow: "0 2px 5px 0 rgba(0,0,0,.2)",
    "&:hover": {
      transform: "scale(1.02) translateY(-3px)",
      boxShadow: "0 7px 12px 0 rgba(0,0,0,.3)"
    }
  },
  planSelected: {
    transform: "scale(1.02) translateY(-3px)",
    boxShadow: "0 7px 12px 0 rgba(0,0,0,.3)"
  },
  radio: {
    color: "#ccc",
    "&$checked": {
      color: "#FF6666"
    },
    "& .MuiButtonBase-root": {
      padding: "0px !important",
      marginRight: "0px !important"
    }
  },
  checkedRadio: {
    color: "#FF6666"
  }
};

class SpecificMembership extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      textShown: false,
      mapShown: false,
      membership: { membership_type: [], prices: [] },
      selected: null,
      checkout: false,
      selectedPlan: null
    };
  }
  async componentDidMount() {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/search/membership/${this.props.match.params.id}`;
    const results = await axios.get(ep);

    if (!results.data.success) {
      this.props.history.push("/");
      this.setGlobal({ loading: false });
      return;
    }
    if (results.data.success && results.data.data.isArchived) {
      this.props.history.push("/partners");
      this.setGlobal({ loading: false });
      return;
    }
    this.setState({ membership: results.data.data });
    this.setGlobal({ loading: false });
  }
  getLocation(locations) {
    const data = locations.filter(
      e => e.address === this.state.program.address1
    );
    return data.length ? (
      <div
        lat={data[0].lat}
        lng={data[0].lng}
        style={{
          backgroundImage: `url(${OrangePin})`,
          backgroundSize: "cover",
          height: 30,
          width: 22,
          cursor: "pointer",
          zIndex: 10,
          position: "relative",
          bottom: 15,
          right: 15
        }}
      ></div>
    ) : (
      <div
        lat={this.state.program.main_lat}
        lng={this.state.program.main_long}
        style={{
          backgroundImage: `url(${OrangePin})`,
          backgroundSize: "cover",
          height: 30,
          width: 22,
          cursor: "pointer",
          zIndex: 10,
          position: "relative",
          bottom: 15,
          right: 15
        }}
      ></div>
    );
  }
  render() {
    if (!this.global.organizationInfo) {
      return null;
    }

    return this.state.checkout ? (
      <Checkout
        form={this.state.membership.formId}
        program={this.state.membership}
        organization={this.state.membership.organization_title}
        selected={this.state.selected}
        close={_ => this.setState({ checkout: false })}
        type={"Membership"}
      />
    ) : (
      <div className="containerall">
        <div className="leftcontainerprogram">
          <div className="titledivision">
            <h1 className="maintitleprogram">
              {this.state.membership.membership_name}
            </h1>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <h4
                className="org-title"
                onClick={_ => this.props.history.push(`/preview`)}
              >
                {this.state.membership.organization_title}
              </h4>
              <button
                className="partnerbutton"
                style={{ borderRadius: "10px", marginRight: "10px" }}
              >
                Share
              </button>
            </div>
          </div>

          <div className="maincontainerdiv">
            <img
              className="imageprogram"
              alt="member"
              src={
                this.state.membership.membership_image === 0
                  ? MemberShip1
                  : MemberShip2
              }
            />

            <div
              className="widgetdata"
              style={{
                textAlign: "center",
                marginLeft: "50px"
              }}
            >
              <div
                className="box"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                  marginBottom: "10px"
                }}
              >
                <img src={LiveView2} alt="icon" className="icon-img" />
                <p className="icon-text">
                  {this.state.membership.membership_type.map((e, i) => {
                    const element = JSON.parse(e);
                    return i + 1 ===
                      this.state.membership.membership_type.length ? (
                      element.name
                    ) : (
                      <p>{element.name + ","}</p>
                    );
                  })}
                </p>
              </div>

              {this.state.membership.show_max_amount && (
                <div
                  className="box"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "10px",
                    marginBottom: "10px"
                  }}
                >
                  <img src={LiveView3} alt="icon" className="icon-img" />

                  <p className="icon-text">
                    Total Memberships Available:{" "}
                    {this.state.membership.max_amount}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="box-size">
            <h3 className="sub-title">Membership Description</h3>

            <p
              style={{
                fontWeight: "400",
                fontFamily: `"Open sans", sans-serif`,
                whiteSpace: "pre-line"
              }}
            >
              {this.state.membership.description}
            </p>
          </div>

          <div className="box-size">
            <h3 className="sub-title">Additional Perks</h3>
            <p
              style={{
                fontWeight: "400",
                fontFamily: `"Open sans", sans-serif`,
                whiteSpace: "pre-line"
              }}
            >
              {this.state.membership.additional_perks}
            </p>
          </div>

          <div className="box-size">
            <h3 className="sub-title">Cancellation and Refunds</h3>
            <p
              style={{
                fontWeight: "400",
                fontFamily: `"Open sans", sans-serif`,
                whiteSpace: "pre-line"
              }}
            >
              {/*TODO*/}
            </p>
          </div>

          <div className="box-size">
            <h3 className="sub-title">Location</h3>
            <div className="map-size">
              <p>Address : {this.state.membership.concat}</p>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyBX7aSRy1hovgaRvgemOy0Wl7aizjTJ1QE"
                }}
                defaultZoom={9}
                defaultCenter={{
                  lat: parseFloat(this.global.organizationInfo.main_lat),
                  lng: parseFloat(this.global.organizationInfo.main_long)
                }}
                options={{ gestureHandling: "greedy" }}
              >
                <div
                  lat={this.state.membership.main_lat}
                  lng={this.state.membership.main_long}
                  style={{
                    backgroundImage: `url(${OrangePin})`,
                    backgroundSize: "cover",
                    height: 30,
                    width: 22,
                    cursor: "pointer",
                    zIndex: 10,
                    position: "relative",
                    bottom: 15,
                    right: 15
                  }}
                ></div>
              </GoogleMapReact>
            </div>
          </div>
        </div>

        <div className="rightcontainerprogram">
          <h2 className="right-cont-name">Membership Plans</h2>

          <div style={{ width: "80%", margin: "0 auto" }}>
            {this.state.membership.prices
              .filter(ele => {
                const e = typeof ele === "string" ? JSON.parse(ele) : ele;
                if (e.archived) {
                  return;
                } else return e;
              })
              .map((ele, key) => {
                const e = JSON.parse(ele);
                return (
                  <div
                    className={
                      this.state.selected === key
                        ? this.props.classes.planSelected + " pricingcard"
                        : this.props.classes.plan + " pricingcard"
                    }
                    onClick={_ =>
                      this.setState({ selected: key, selectedPlan: e })
                    }
                    key={key}
                  >
                    <div className="radio-btn-positioning-container">
                      <Radio
                        checked={this.state.selected === key}
                        // onChange={handleChange}
                        value="d"
                        color="default"
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "D" }}
                        size="large"
                        classes={{
                          root: this.props.classes.radio,
                          checked: this.props.classes.checkedRadio
                        }}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <p className="pricingcard-name">
                        {e.membership_term} Month Term <br />{" "}
                      </p>

                      <p className="pricingcard-days">
                        {e.auto_renew
                          ? "Subscription Membership"
                          : "Standard Membership"}
                      </p>
                    </div>
                    <div style={{ width: "50px" }}>
                      <p className="pricingcard-amount">${e.price_per_term}</p>
                    </div>
                  </div>
                );
              })}

            {this.state.selected !== null && (
              <SummarySubTotalMembership
                plans={this.state.membership.prices}
                selected={this.state.selected}
              />
            )}

            {this.state.membership.isCartDisabled ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <button
                  style={{
                    width: `70%`,
                    padding: `10px`,
                    backgroundColor: `rgb(52, 63, 100)`,
                    color: `white`,
                    marginTop: "10px",
                    fontWeight: `bold`,
                    border: 0,
                    cursor: "not-allowed"
                  }}
                >
                  Register Here Now
                </button>
              </div>
            ) : this.state.selectedPlan?.auto_renew ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <button
                  style={{
                    width: `70%`,
                    padding: `10px`,
                    backgroundColor: `rgb(52, 63, 100)`,
                    color: `white`,
                    marginTop: "10px",
                    fontWeight: `bold`,
                    border: 0,
                    cursor: "not-allowed"
                  }}
                >
                  Register Here Now
                </button>
                <div>
                  <i className="bi bi-info-circle" /> Recurring memberships must
                  be checked out individually.
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <button
                  style={{
                    width: `70%`,
                    padding: `10px`,
                    backgroundColor: `rgb(52, 63, 100)`,
                    color: `white`,
                    fontWeight: `bold`,
                    border: 0,
                    marginTop: "40px",
                    cursor: "not-allowed"
                  }}
                >
                  Add to Cart
                </button>
                <span>-------- OR --------</span>
                <button
                  style={{
                    width: `70%`,
                    padding: `10px`,
                    backgroundColor: `rgb(211,52,49)`,
                    color: `white`,
                    marginTop: "10px",
                    fontWeight: `bold`,
                    border: 0,
                    cursor: "not-allowed"
                  }}
                >
                  Register Here Now
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(SpecificMembership);
